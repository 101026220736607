import { useState, useEffect } from "react";

import { UNDER_MAINTENANCE, UNDER_MAINTENANCE_UNTIL } from "../../../config/variables";

import Cross from "../../../assets/svg/cross.svg";

const MaintenanceBanner = () => {
  const [hide, setHide] = useState(true);
  const [timeLeft, setTimeLeft] = useState("00D:00H:00M:00S");

  useEffect(() => {
    try {
      if (UNDER_MAINTENANCE) {
        const now = new Date().getTime();
        const endTime = new Date(UNDER_MAINTENANCE_UNTIL).getTime();
        const distance = endTime - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft(`${days}D:${String(hours).padStart(2, "0")}H:${String(minutes).padStart(2, "0")}M:${String(seconds).padStart(2, "0")}S`);

        if (distance < 0) {
          setTimeLeft("00D:00H:00M:00S");
          setHide(true);
        } else setHide(false);

        const intervalId = setInterval(() => {
          const now = new Date().getTime();
          const endTime = new Date(UNDER_MAINTENANCE_UNTIL).getTime();
          const distance = endTime - now;

          // Calculate time components
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Format the time left
          setTimeLeft(`${days}D:${String(hours).padStart(2, "0")}H:${String(minutes).padStart(2, "0")}M:${String(seconds).padStart(2, "0")}S`);

          // If the countdown is finished, clear the interval
          if (distance < 0) {
            if (intervalId) clearInterval(intervalId);
            setHide(true);
            setTimeLeft("00D:00H:00M:00S"); // Optionally set to zero or hide the banner
          } else setHide(false);
        }, 1000);

        // Cleanup interval on component unmount
        return () => {
          if (intervalId) clearInterval(intervalId);
        };
      }
    } catch (err) {
      console.log("Failed to timer action: ", err);
      setHide(true);
    }
  }, [UNDER_MAINTENANCE, UNDER_MAINTENANCE_UNTIL]);

  return (
    <>
      {UNDER_MAINTENANCE && !hide && (
        <div className="bg-orange-600 py-[4px] px-[24px] flex flex-col lg:flex-row gap-[16px] items-center justify-center bg-cover">
          <p className="text-[18px] text-white text-center">We're currently in maintenance. New update in {timeLeft}</p>
          <img src={Cross} alt="cross" className="w-[22px] h-[22px] cursor-pointer absolute right-[12px]" onClick={() => setHide(true)} />
        </div>
      )}
    </>
  );
};

export default MaintenanceBanner;
