import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { photo_data } from "./config/const";
import { loadImages } from "./utils/loading";
import Loading from "./components/loading";
import Banners from "./components/banners";
import ScrollToTop from "./components/common/scroll-to-top";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const Download = React.lazy(() => import("./page/download"));
const DownloadMac = React.lazy(() => import("./page/download/mac"));
const DownloadWindows = React.lazy(() => import("./page/download/windows"));
const DownloadAndroid = React.lazy(() => import("./page/download/android"));
const DownloadIOS = React.lazy(() => import("./page/download/ios"));
const DownloadChrome = React.lazy(() => import("./page/download/chrome"));

const FAQPage = React.lazy(() => import("./page/faq-page"));

const Home = React.lazy(() => import("./page/home"));
const Privacy = React.lazy(() => import("./page/privacy"));
const Pricing = React.lazy(() => import("./page/pricing"));
const Servers = React.lazy(() => import("./page/servers"));
const WhatIsVPN = React.lazy(() => import("./page/whatisvpn"));
const WhatIsMyIPAddress = React.lazy(() => import("./page/whatismyipaddress"));

const ResetPassword = React.lazy(() => import("./page/reset-password"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadImages(photo_data)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Loading Image Error: ", error);
        setIsLoading(true);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <main>
          <ScrollToTop />
          <Banners />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/marketing" element={<Navigate to="/" />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/servers" element={<Servers />} />
            <Route path="/what-is-vpn" element={<WhatIsVPN />} />
            <Route path="/what-is-my-ip-address" element={<WhatIsMyIPAddress />} />
            <Route path="/download" element={<Download />} />
            <Route path="/macos-vpn" element={<DownloadMac />} />
            <Route path="/windows-vpn" element={<DownloadWindows />} />
            <Route path="/android-vpn" element={<DownloadAndroid />} />
            <Route path="/ios-vpn" element={<DownloadIOS />} />
            <Route path="/chrome-vpn" element={<DownloadChrome />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/macos-faq" element={<FAQPage page={"macos-faq"} />} />
            <Route path="/windows-faq" element={<FAQPage page={"windows-faq"} />} />
            <Route path="/android-faq" element={<FAQPage page={"android-faq"} />} />
            <Route path="/ios-faq" element={<FAQPage page={"ios-faq"} />} />
            <Route path="/chrome-faq" element={<FAQPage page={"chrome-faq"} />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
          </Routes>
        </main>
        {/* <Footer /> */}
      </Suspense>
    </Router>
  );
}

export default App;
